import AppTitle from '../components/AppTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Typography } from 'antd';
import { Outlet } from 'react-router-dom';
import Logo from '../components/Logo';
import { Row, Col } from 'antd';
import { isMobile } from 'react-device-detect';
const { Header, Content, Footer } = Layout;
const { Text } = Typography;
export default function HomePage() {
  return (
    <>
      {/* <AppTitle title="Home" />

      <h2>This is HomePage</h2>
      <Link to="/auth/login">Log In</Link> */}
      <Layout>
        <Header className="sticky-header p-4">
          <div className="header-content">
            <Logo />

            <Link to="/auth/login" className="login-link">
              {/* <h2>Log In</h2> */}
              <Text style={{ fontSize: '20px' }} type="success" underline>
                Log In
              </Text>
            </Link>
          </div>
        </Header>

        <Content
          className="bg-white p-2"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vTReGDZt_lQZVHr3XkCBi8OEriRdifqNuZfzebuRHMvChRh1LblemBLFk7G0OITNRClKcyqgi16WAWa/embed?start=false&loop=true&delayms=60000"
            // style={iframeStyles}
            className="responsive-iframe"
            width="70%"
            height="530"
            frameBorder={0}
            allowFullScreen={true}
            mozAllowFullScreen={true}
            webkitAllowFullScreen={true}
          ></iframe>
        </Content>

        <Footer
          className={`bg-white flex-item gap-2 space-between p-2 ${isMobile ? 'flex-column' : ''}`}
        >
          <Text type="secondary">© {new Date().getFullYear()} Upplaud. All rights reserved.</Text>
          <div style={{ marginRight: '300px' }} className="flex-item gap-4">
            <Link to="/privacy-policy">
              <Text style={{ fontSize: '20px' }} type="danger" underline>
                Privacy Policy
              </Text>
            </Link>
            <Link to="/terms-of-service">
              <Text style={{ fontSize: '20px', marginLeft: '20px' }} type="danger" underline>
                Terms of Service
              </Text>
            </Link>
          </div>
        </Footer>
      </Layout>
    </>
  );
}
