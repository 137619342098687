import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  setEmails,
  hasError,
  setEmailsForGuestPage,
} from '../../reducers/emailsSlice';
import axios from '../../utils/axios';

export default function ConstantLoading() {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [readEmailCount, setReadEmailCount] = useState(0);
  const { originURL, originType, emailForSocialConnect, countContact } = useSelector(
    (state) => state.emails
  );
  const { user } = useSelector((state) => state.user); // Assuming `user` contains `profile.email`

  const extractEmail = (text) => {
    let name = '';
    let email = '';

    if (text.includes('<')) {
      const parts = text.split('<');
      name = parts[0].trim();
      email = parts[1].slice(0, -1).trim();
    } else {
      email = text.trim();
    }

    return { name, email };
  };
  const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const fetchEmails = async (accessToken) => {
    if (!accessToken) return;

    try {
      const response = await axios.get(`/constant?accessToken=${accessToken}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      console.log('FinalTest', response.data);
      const segments = response.data;
      console.log(response.data);
      segments.map(async (segment) => {
        const segmentName = segment.segmentName;
        console.log(segmentName);
        await sendContactsToBackend(
          segment.members.map((member) => {
            return {
              contactName: member.name,
              contactEmail: member.email,
              // sentCount: email['Sent'],
              // receivedCount: email['Received'],
              sentCount: 1,
              receivedCount: 1,
            };
          }),
          emailForSocialConnect,
          'constant' + generateRandomNumber(1, 10000000) + '@Contact.com',
          segmentName
        );
      });
      window.location.href = originURL;
    } catch (error) {
      console.log(error);
    }
  };

  const generateCSV = (sent, received, name, userEmail) => {
    const csvRows = [];
    Object.keys(sent)
      .sort((email1, email2) => sent[email2] - sent[email1])
      .forEach((email) => {
        if (sent[email] >= 1 && received[email] >= 1)
          csvRows.push({
            contactEmail: email,
            contactName: name[email].length === 0 ? email : name[email],
            sentCount: sent[email],
            receivedCount: received[email],
          });
      });

    // dispatch(originType === 'user' ? setEmails(csvRows) : setEmailsForGuestPage(csvRows));
    return csvRows;
  };

  const sendContactsToBackend = async (contacts1, userEmail, mGmail, name) => {
    try {
      const response = await axios.post(
        '/contacts/mailchimp',
        {
          contacts1,
          userEmail,
          email: mGmail,
          type: 'Mailchimp',
          usertype: originType,
          count: countContact,
          name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to send contacts to backend');
      }
      console.log('Contacts sent successfully:', response.data);
    } catch (error) {
      console.error('Failed to send contacts to backend:', error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    if (accessToken) {
      if (accessToken.length > 0) {
        fetchEmails(accessToken);
      }
    }
  }, []);

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <p>Loading your Constant Contact Account's Email list</p>
    </main>
  );
}
