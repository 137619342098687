import Logo from '../components/Logo';
import { Layout, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { Link, Outlet } from 'react-router-dom';

const { Text } = Typography;
const { Header, Content, Footer } = Layout;

export default function CompactVoteLayout() {
  return (
    <Layout>
      <Header
        className="sticky-header"
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Ensure the Header components are spaced out
          alignItems: 'center', // Center vertically
          padding: '0 20px', // Optional: some padding for spacing
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', // Center items horizontally within the div
            alignItems: 'center', // Center items vertically
            gap: '10px',
            flex: 1, // Allow the div to take up available space
            marginTop: '20px',
          }}
        >
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <img width="30" height="30" alt="Facebook" src="/social_buttons/facebook.png" />
          </a>

          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
            <img width="30" height="30" alt="WhatsApp" src="/social_buttons/whatsapp.png" />
          </a>

          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <img width="30" height="30" alt="Twitter" src="/social_buttons/twitter.png" />
          </a>

          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <img width="30" height="30" alt="LinkedIn" src="/social_buttons/linkedIn.jpg" />
          </a>

          <a href="mailto:someone@example.com">
            <img width="30" height="30" alt="Email" src="/social_buttons/download.png" />
          </a>
        </div>
      </Header>

      <Content className="h-100 bg-white" style={{ paddingBottom: 50 }}>
        <Outlet />
      </Content>

      <Footer
        className={`bg-white flex-item gap-2 space-between p-2 ${isMobile ? 'flex-column' : ''}`}
      >
        <Text type="secondary">© {new Date().getFullYear()} Upplaud. All rights reserved.</Text>
        <div className="flex-item gap-2">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>
      </Footer>
    </Layout>
  );
}
