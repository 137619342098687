import PropTypes from 'prop-types';
import Logo from '../components/Logo';
import AppTitle from '../components/AppTitle';

import illustration from '../assets/images/illustration.png';
import { Image, Typography, Layout } from 'antd';
import { isMobile, isDesktop } from 'react-device-detect';
import { Space, Dropdown, Badge, Row, Col, Flex } from 'antd';
LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const { Sider, Content } = Layout;

export default function LoginLayout({ children, title, pageHeading }) {
  return (
    // <Layout className="h-100vh">
    <>
      <AppTitle title={title || ''} />
      <Row>
        <Col xs={24} sm={15}>
          {' '}
          <Content className="p-4" style={{ background: 'rgb(255 192 203 / 5%)' }}>
            <Logo />

            <div className="text-center p-1">
              {/* <Typography.Title level={2}>{pageHeading || 'Hi, Welcome back'}</Typography.Title> */}
              {/* <Image src={illustration} preview={false} /> */}
              <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vTReGDZt_lQZVHr3XkCBi8OEriRdifqNuZfzebuRHMvChRh1LblemBLFk7G0OITNRClKcyqgi16WAWa/embed?start=false&loop=true&delayms=60000"
                // style={iframeStyles}
                className="responsive-iframe"
                width="100%"
                height="500"
                frameBorder={0}
                allowFullScreen={true}
                mozAllowFullScreen={true}
                webkitAllowFullScreen={true}
              ></iframe>
            </div>
          </Content>
        </Col>
        <Col xs={24} sm={9}>
          {/* <Sider theme="light" width={isMobile ? '100%' : 480} className=""> */}
          <div
            className={`flex-item flex-column align-items-inherit p-4 h-100 w-100 ${
              isDesktop ? 'flex-center' : ''
            }`}
          >
            {/* {isMobile && <Logo />} */}

            {children}
          </div>
          {/* </Sider> */}
        </Col>
      </Row>
    </>
    // </Layout>
  );
}
